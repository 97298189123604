import React, { useState, useEffect, useRef, useMemo, useLayoutEffect } from 'react';
import { Radio, Button, Checkbox, message } from 'antd';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import './index.less';

const IconFont = Loader.loadBaseComponent('IconFont');

function TaskTimePartPick({ value, onChange }) {
  const [timeConfig, setTimeConfig] = useState(function() {
    const initValue = Object.assign({}, { executionType: 0, executionDay: [], executionTime: [] }, value);
    if (initValue.executionTime) {
      initValue.executionTime = initValue.executionTime.map(times => times.map(time => Math.round(time / (60 * 60 * 1000))));
    }
    return initValue;
  });

  const timesSpace = useMemo(() => {
    const times = [];
    if (!timeConfig.executionTime) {
      return times;
    }
    timeConfig.executionTime.forEach(v => {
      if (v.length) {
        const [start, end] = v;
        for (let i = start; i < end + 1; i++) {
          times.push(i);
        }
      }
    });
    return times;
  }, [timeConfig.executionTime]);

  useEffect(() => {
    if (!onChange) {
      return;
    }
    const { executionType, executionDay, executionTime } = timeConfig;
    if (executionType === 0) {
      onChange({ executionType });
    } else {
      onChange({
        executionType,
        executionDay,
        executionTime: executionTime.filter(v => v.length !== 0).map(times => times.map(time => time * 60 * 60 * 1000))
      });
    }
  }, [timeConfig]);

  const onAdd = function() {
    setTimeConfig(config => {
      const times = _.cloneDeep(config.executionTime);
      times.push([]);
      return { ...config, executionTime: times };
    });
  };

  const onDelete = function(index) {
    setTimeConfig(config => {
      let newConfig = _.cloneDeep(config);
      let times = newConfig.executionTime;
      times.splice(index, 1);
      newConfig = { ...newConfig, executionTime: times };
      return Utils.isEqual(newConfig, config) ? config : newConfig;
    });
  };

  const onChangeTime = function(range, i) {
    setTimeConfig(config => {
      let newConfig = _.cloneDeep(config);
      const times = newConfig.executionTime;
      times[i] = range;
      newConfig = { ...newConfig, executionTime: times };
      return Utils.isEqual(newConfig, config) ? config : newConfig;
    });
  };

  const onChageType = e => {
    setTimeConfig(config => {
      const newConfig = { ...config, executionType: e.target.value };
      if (!newConfig.executionTime.length) {
        newConfig.executionTime = [[0, 24]];
      }
      if (!newConfig.executionDay.length) {
        newConfig.executionDay = Dict.map.weeks.map(v => v.value);
      }
      return newConfig;
    });
  };
  return (
    <div className="task-time-part-pick">
      <div className="type-radio">
        <Radio.Group value={timeConfig.executionType} onChange={onChageType}>
          <Radio value={0}>全时</Radio>
          <Radio value={1}>自定义</Radio>
        </Radio.Group>
      </div>
      {timeConfig.executionType === 1 && (
        <div className="week-select">
          <Checkbox.Group value={timeConfig.executionDay} onChange={keys => setTimeConfig(config => ({ ...config, executionDay: keys }))}>
            {Dict.map.weeks.map(v => (
              <Checkbox value={v.value}>{v.label}</Checkbox>
            ))}
          </Checkbox.Group>
        </div>
      )}
      {timeConfig.executionType === 1 && (
        <div className="time-pick">
          {timeConfig.executionTime.map((v, i) => (
            <TimePickRange
              key={i}
              onDelete={i !== timeConfig.executionTime.length - 1 && timeConfig.executionTime.length !== 1 ? () => onDelete(i) : false}
              value={v}
              timesSpace={v.length === 2 ? timesSpace.filter(v2 => !(v2 >= v[0] && v2 <= v[1])) : timesSpace}
              index={i}
              onAdd={i === timeConfig.executionTime.length - 1 ? onAdd : false}
              onChange={range => onChangeTime(range, i)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function TimePickRange({ value, onAdd, onDelete, onChange, timesSpace }) {
  const [rangeValue, setRnageValue] = useState(value || []);
  const [visible, setVisible] = useState(false);
  const boxRef = useRef(null);
  const timeDicts = useMemo(() => new Array(25).fill(true).map((_, i) => i), []);
  const timeText = useMemo(() => {
    if (rangeValue[0] === undefined || rangeValue[1] === undefined) {
      return '';
    }
    return `${rangeValue[0] >= 10 ? rangeValue[0] : '0' + rangeValue[0]}:00 - ${rangeValue[1] >= 10 ? rangeValue[1] : '0' + rangeValue[1]}:00`;
  }, [rangeValue]);

  useLayoutEffect(() => {
    const container = ReactDOM.findDOMNode(boxRef.current).querySelector('.ant-time-picker');

    function openVisibelProtal() {
      setVisible(true);
    }

    container.addEventListener('click', openVisibelProtal, false);
    return () => container.removeEventListener('click', openVisibelProtal, false);
  }, []);

  useLayoutEffect(() => {
    function hideVisible(e) {
      const container = ReactDOM.findDOMNode(boxRef.current).querySelector('.ant-time-picker');
      if (e.path && e.path.includes(container)) {
        return;
      }
      setVisible(false);
    }

    document.body.addEventListener('click', hideVisible, false);
    return () => document.body.removeEventListener('click', hideVisible, false);
  }, []);

  useEffect(() => {
    if (rangeValue && rangeValue[0] !== undefined && rangeValue[1] !== undefined) {
      const arr = createTimeRangeList(...rangeValue);
      if (_.intersection(timesSpace, arr).length !== 0) {
        setRnageValue([]);
        message.warn('时间段存在交叉请重新选择！');
      } else {
        onChange(rangeValue);
      }
    }
  }, [rangeValue, timesSpace]);

  useEffect(() => {
    setRnageValue(() => value);
  }, [value]);

  const selectStart = function(e) {
    const start = +e.target.dataset.value;
    setRnageValue(value => [start, value[1]]);
  };
  const selectEnd = function(e) {
    const end = +e.target.dataset.value;
    setRnageValue(value => [value[0], end]);
  };
  const [start, end] = rangeValue;
  return (
    <div className="time-range-pick" ref={boxRef}>
      <span class="ant-time-picker">
        <input class="ant-time-picker-input" type="text" placeholder="请选择时间段" id="" value={timeText} />
        <span class="ant-time-picker-icon">
          <IconFont type="icon-S_Edit_ClockEnd"></IconFont>
        </span>
        {visible && (
          <div className="protal-time-group">
            <ul className="protal-time-item">
              {timeDicts.map(v => {
                const disabled = !(!timesSpace.includes(v) && !(end && v >= end));
                return (
                  <li onClick={e => !disabled && selectStart(e)} data-value={v} className={`${start === v ? 'active' : ''} ${disabled ? 'disabled' : ''}`}>{`${
                    v >= 10 ? v : '0' + v
                  }:00`}</li>
                );
              })}
            </ul>
            <ul className="protal-time-item">
              {timeDicts.map(v => {
                const disbled = !(!timesSpace.includes(v) && !(start && v <= start));
                return (
                  <li onClick={e => !disbled && selectEnd(e)} data-value={v} className={`${end === v ? 'active' : ''} ${disbled ? 'disabled' : ''}`}>{`${
                    v >= 10 ? v : '0' + v
                  }:00`}</li>
                );
              })}
            </ul>
          </div>
        )}
      </span>
      {onAdd && (
        <Button onClick={onAdd}>
          <IconFont type="icon-S_Edit_PlusLine" />
          添加时间段
        </Button>
      )}
      {onDelete && (
        <Button onClick={onDelete}>
          <IconFont type="icon-S_Edit_MinusLine" />
          删除时间段
        </Button>
      )}
    </div>
  );
}

function createTimeRangeList(start, end) {
  const arr = [];
  for (let i = start; i < end + 1; i++) {
    arr.push(i);
  }
  return arr;
}

export default TaskTimePartPick;
